@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./typography.css";
@import "./buttons.css";
@import "./layout.css";
@import "./colors.css";
@import "./menu-button.css";

html {
    @apply text-contrast text-base bg-bg
}
.uniform__potty {
    position: absolute;
    left: -9999px;
}
.col {
    @apply p-8
}
.section-bg-img .col-7 {
    background-image: url('../img/dots-bg.png');
    background-size: cover;
}
@media screen(lg) {
    .section-grid {
        @apply grid relative
    }
    .section-grid .col-5 {
        @apply bg-bg -mt-[var(--offset)] rounded-sm
    }
    .col:has(h2) {
        @apply pt-6
    }
    .col:first-child {
        @apply pl-container pr-12 pb-12
    }

    .col:last-child {
        @apply pr-container pl-12 pb-12
    }

    .section-grid > .col-5 > * {
        @apply max-w-[45ch] mx-0
    }

    .section-grid:has(.col-7 + .col-5), .section-grid:has(.col-5 + .col-7) {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen(xl) {
    .section-grid:has(.col-7 + .col-5) {
        grid-template-columns: 7fr 5fr;
    }

    .section-grid:has(.col-5 + .col-7) {
        grid-template-columns: 5fr 7fr;
    }
}
