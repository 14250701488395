@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Euclid';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/EuclidCircularB-Regular-WebS.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Euclid';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/EuclidCircularB-Medium-WebS.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* @link https://utopia.fyi/type/calculator?c=320,16,1.2,1600,18,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
    --step--2: clamp(0.6944rem, 0.6881rem + 0.0319vi, 0.72rem);
    --step--1: clamp(0.8333rem, 0.8167rem + 0.0833vi, 0.9rem);
    --step-0: clamp(1rem, 0.9688rem + 0.1563vi, 1.125rem);
    --step-1: clamp(1.2rem, 1.1484rem + 0.2578vi, 1.4063rem);
    --step-2: clamp(1.44rem, 1.3605rem + 0.3973vi, 1.7578rem);
    --step-3: clamp(1.728rem, 1.6107rem + 0.5866vi, 2.1973rem);
    --step-4: clamp(2.0736rem, 1.9054rem + 0.8412vi, 2.7466rem);
    --step-5: clamp(2.4883rem, 2.2521rem + 1.1811vi, 3.4332rem);
}
@layer utilities {
    h2.block-heading {
        @apply text-lg md:text-xl mb-4
    }
    .article a {
        @apply border-b-2 border-contrast hover:border-primary
    }
    .article {
        --body-font-size: theme(fontSize.base);
        --heading-line-height: 1.2;
        --heading-margin: 2em;
        --body-line-height: 1.4;
        --spacing: 2rem;

        line-height: var(--body-line-height);
        font-size: var(--body-font-size);
        max-width: 60ch;
    }

    .article > * + * {
        @apply mt-4;
    }

    .article h2, .article h3:not(.accordion-heading), .article h4 {
        margin-top: var(--heading-margin);
    }
    .article h2 {
        font-size: theme(fontSize.lg);
    }
    .article h3:not(.accordion-heading) {
        font-size: theme(fontSize.md);
    }
    .article h4 {
        font-size: theme(fontSize.base);
    }

    .article ul:not(.download-list, .toggle), .article ol {
        padding-left: 1.25em;
    }

    .article ul :where(ul, ol),
    .article ol :where(ul, ol) {
        padding-left: 1em;
    }

    .article ul {
        list-style-type: disc;
    }

    .article ol {
        list-style-type: decimal;
    }

    .article ul li::marker,
    .article ol li::marker {
        color: currentColor;
    }

    .article img {
        margin: 0 auto;
    }
    .article video, .article iframe, .article picture, .article figure {
        @apply my-8;
    }
    .article figcaption {
        margin-top: calc(var(--spacing) / 2);
        color: currentColor;
        text-align: center;
    }

    .article em {
        font-style: italic;
    }

    .article strong {
        font-weight: bold;
    }

    .article s {
        text-decoration: line-through;
    }

    .article u {
        text-decoration: underline;
    }

    .article mark {
        background-color: #eee;
        color: inherit;
        padding: 0 0.25em;
    }

    .article blockquote {
        padding-left: 1em;
        border-left: 3px solid currentColor;
        font-style: italic;
    }

    .article hr {
        margin: calc(var(--spacing) * 1.6666666667) 0;
        background: #eee;
        height: 1px;
    }
}
.section {
    counter-reset: section-number;
}
.section-grid__number {
    content: counter(section-number);
}
.section-grid li {
    @apply mb-4 flex gap-4 max-w-[36ch] relative
}
.section-grid li:before {
    content: '';
    width: 12px;
    height: 2.6rem;
    flex-shrink: 0;
    display: block;
    transform: translateY(.35rem);
    background-image: radial-gradient(rgba(var(--contrast), 1) 1px, transparent 0);
    background-size: 4px 4px;
    background-position: -0.5px -0.5px;
}
.section-grid li:after {
    content: '';
    width: 12px;
    height: 2.6rem;
    flex-shrink: 0;
    display: block;
    position: absolute;
    transform: translateY(.35rem);
    left: 0;
    background-image: linear-gradient(150deg, rgba(var(--bg), 0), rgba(var(--bg), 1));
}