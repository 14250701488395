@layer utilities {
    :root {
        --padding-inline: theme(space.4);
        --container-max-width: 84rem;
        --container-width: min((100vw - var(--padding-inline) * 2), var(--container-max-width));
        --container-space: max(var(--padding-inline), (100vw - var(--container-max-width)) / 2 + var(--padding-inline));
        --offset: 6rem;
    }
    .container {
        @apply max-w-[var(--container-width)] mx-auto;
    }

    .p-container {
        @apply px-[var(--container-space)] scroll-ps-[var(--container-space)] scroll-pe-[var(--container-space)]
    }

    .pr-container {
        @apply pr-[var(--container-space)] scroll-pe-[var(--container-space)]
    }

    .pl-container {
        @apply pl-[var(--container-space)] scroll-ps-[var(--container-space)]
    }
}